import React from 'react'
import PriceFormat from './PriceFormat'

import {Wallet} from '@styled-icons/ionicons-outline/Wallet'
import { useTranslation } from 'next-i18next'

const TotalFooter = ({products}) => {
    const totalSum = products.reduce((total, product) => 
                            total + (product.count * product.price), 0)
    const productCount = products.filter(p => p.type !== "inspiration").reduce((count, product) => count + product.count, 0)
    const { t } = useTranslation()

    if(!productCount) return <></>

    return (
        <footer className="flex items-center mx-6 sm:mx-8 p-6 text-gray-900 bg-gray-50 mb-10">
            <Wallet className="h-6 pr-4 flex-shrink-0 border-r mr-4" /><p><b>{productCount}</b> {t('total-pricing')} <b className="whitespace-nowrap"><PriceFormat  value={totalSum} /></b></p>
        </footer> 
    )
};

export default TotalFooter;
