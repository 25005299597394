import React, {useEffect, useState, useContext} from 'react';

import { StreamContext } from 'react-activity-feed';
import useRedirectToProfile from "../useRedirectToProfile"
import { useTranslation } from 'next-i18next'

export default ({userToFollow}) => {
    const stream = useContext(StreamContext)
    const {user, client} = stream
    const [followed, setFollowed] = useState(null)
    const currentUser = client.feed('timeline', user.id);
    const redirect = useRedirectToProfile()
    const { t } = useTranslation()


    useEffect(() => {
        if(!userToFollow) return
        
        isFollowed(userToFollow)
    }, [userToFollow])

    const isFollowed = async userToFollow => {
        const response = await currentUser.following({ offset: 0, limit: 2, filter: [`user:${userToFollow}`] })
        const isFollowed = !!response.results.length
        setFollowed(isFollowed)
    }

    const toggleFollow = async () => { 
        if(user.id == "global") {
            redirect()
            return
        }

        if(followed === true) {
            await currentUser.unfollow('user', userToFollow)
            console.log(user.id + " unfollowed " + userToFollow)
            setFollowed(false)
        }
        else {
            await currentUser.follow('user', userToFollow)
            console.log(user.id + " followed " + userToFollow)
            setFollowed(true)

             // Client-side: Instantiate a feed for feed group 'user', user id '1'  
            // and a security token generated server side 
            const usersFeed = client.feed('user', user.id, user.token); 
            
            // Create an activity object 
            const activity = { 
                actor: client.currentUser, 
                verb: 'follow', 
                object: client.user(userToFollow), 
                to: [`notification:${userToFollow}`]
            } 
            await usersFeed.addActivity(activity)
        }
    }
    
    return (
        <>
        {user.id != userToFollow &&
                <button 
                    onClick={toggleFollow}
                    className="bg-gray-100 hover:bg-gray-200 inline-flex items-center rounded-full p-3">
                        {followed ? t('following') : t('follow')}</button>
        }
        </>
    )
}