import React, {useEffect, useRef} from 'react'

import Popup from 'reactjs-popup'

const StyledTooltip = ({ isOpen, trigger, position, children }) => {

  const ref = useRef()
  const close = () => ref.current.close()

  useEffect(() => {
    if(isOpen === false) close()
  }, [isOpen])


  return (
    <>
      <Popup
        ref={ref} 
        trigger={trigger}
        position={position}
        arrow={false}>
        {children}
      </Popup>
    </>
  )
}

export { StyledTooltip }
