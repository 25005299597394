import React, {useState, useEffect, Suspense, lazy} from 'react'


import {StyledTooltip} from './StyledTooltip'
import {Pinterest} from '@styled-icons/entypo-social/Pinterest'
import {Link45deg as Link} from '@styled-icons/bootstrap/Link45deg'
import {PinterestShareButton} from 'react-share'
import {CopyToClipboard} from 'react-copy-to-clipboard'
import {Video} from '@styled-icons/boxicons-regular/Video'
import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'
import BoardImage from "./BoardImage"
import { Download } from '@styled-icons/evaicons-solid/Download'

const IconContainer = props => (
  <div
    {...props}
    className="w-24 flex-1 flex flex-col items-center"
  >
    {props.children}
  </div>
)
const Button = props => (
    <a
      {...props}
      className={`h-14 w-14 bg-gray-100 text-gray-700 flex items-center justify-center rounded-full ${props.className}`}
    >
      {props.children}
    </a>
  )

const Share = ({boardId, image, description = "Wohnly Mood Board", children, title, isImageDirty}) => {
    const [showShare, setShowShare] = useState(null)
    const [showTimelapse, setShowTimelapse] = useState(false)
    const [imageUrl, setImageUrl] = useState(image)
    const [copied, setCopied] = useState(false)
    const { t } = useTranslation()
    const Timelapse = dynamic(() => import("./Timelapse"), { ssr: false })
    const url = `${
      process.env.NEXT_PUBLIC_SITEURL
    }/${boardId}`
    const imageBase64 = imageUrl ? Buffer.from(imageUrl).toString("base64") : null

    return (
        <>
            <StyledTooltip isOpen={showShare} position="bottom right" trigger={children}  parentSelector={() => document.querySelector('#sharebutton')}>
                <div className="text-center">
                    <h1 className="font-bold text-lg mb-8">{t('share.board')}</h1>
                    <div className="mb-2">
                      <BoardImage src={image} width="250" height="200" className="object-contain" alt={title} boardId={boardId} isImageDirty={isImageDirty} onImage={setImageUrl} />
                    </div>
                    <div className="flex mx-auto p-8 flex-wrap">
                      {imageUrl && <IconContainer>
                            <Button download={`MoodBoardly-${boardId}`} href={imageUrl} target="_blank">
                              <Download className="h-6 w-6"/>
                            </Button>
                              <span className="text-sm py-2" download>Download</span>
                        </IconContainer>}
                        <IconContainer>
                            <CopyToClipboard text={url} onCopy={() => setCopied(true)}>
                                    <Button className="bg-gray-100 cursor-pointer"><Link className="h-6"/></Button>
                            </CopyToClipboard>
                            <span className="text-sm py-2">{!copied ? t('copy-link') : t('copied')}</span>
                        </IconContainer>
                        {imageBase64 && <IconContainer>
                            <PinterestShareButton className="focus:outline-none" url={url} media={`https://gh-imgproxy-docker.onrender.com/preset:sharp/resize:fill:980:768:0/${imageBase64}`} description={description}>
                                    <Button className="bg-red-600">
                                        <Pinterest className="h-7 text-white" />
                                    </Button>
                            </PinterestShareButton>
                            <span className="text-sm py-2">Pinterest</span>
                        </IconContainer>}
                        {/* {typeof MediaRecorder !== 'undefined' && <IconContainer>
                            <Button className="bg-blue-600 cursor-pointer" onClick={() => {setShowShare(false);setShowTimelapse(true);}}><Video className="h-6 text-white"/></Button>
                            <span className="text-sm py-2">{t('create-video')}</span>   
                        </IconContainer>} */}
                    </div>
                </div>
            </StyledTooltip>
            {/* {showTimelapse && <Timelapse id={id} canvas={canvas} isOpen={showTimelapse} onClose={() => setShowTimelapse(false)}/>} */}
        </>
    )
}

export default Share
