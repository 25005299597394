import React, { useState, useEffect, useContext, createContext } from 'react';

import { StreamContext } from 'react-activity-feed';

const ActivityContext = createContext(null)
export { ActivityContext }

const ActivityContextComponent = ({boardId, time, children}) => {
    const stream = useContext(StreamContext)
    const {client} = stream
    const [activity, setActivity] = useState(null)

    useEffect(() => {
        const response = async () => {
            try {
                const response = await client.getActivities({
                  foreignIDTimes: [
                    {
                      foreignID: boardId,
                      time,
                    },
                  ],
                  reactions: { own: true, counts: true }, //recent: true
                  enrich: true,
                })
                if(!response.results.length) return
                setActivity(response.results[0])
            } catch {
                return
            }
        }
        
        response()
    }, [])

    if(!activity) return <>{children}</>

    return (
      <>
        <ActivityContext.Provider value={{ activity }}>
          {children}
        </ActivityContext.Provider>
      </>
    )
};

export default ActivityContextComponent;
