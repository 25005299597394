import React, { useState, useEffect, useContext } from 'react';
import { loadUserPublicDetailsFromUid } from './FirebaseApp'
import { useRouter } from 'next/router'
import Link from "next/link"

const ProfileThumb = ({ uid, userPublic, showName = false, linkToAccountAdmin = false}) => {
    const [profile, setProfile] = useState(userPublic)
    const router = useRouter()

    const loadProfileUrlFromUid = async uid => {
        setProfile(await loadUserPublicDetailsFromUid(uid))
    } 

    useEffect(() => {
        if(uid) loadProfileUrlFromUid(uid)
    }, [uid]);

    if(!profile || !profile.photoURL) return ""

    const { username, photoURL, displayName } = profile

    const handleClick = (e) => {
      // Only navigate on desktop devices
      if (window.matchMedia('(hover: hover)').matches) {
        router.push(linkToAccountAdmin ? '/dashboard' : `/p/${username}`)
      }
      // On mobile/tablet, prevent navigation to allow dropdown to open
      e.preventDefault()
    }

    return ( 
        <a onClick={handleClick} className="h-full flex items-center space-x-2">
          <img src={photoURL} className="h-12 w-12 object-cover rounded-full hover:ring hover:ring-gray-200" alt={displayName} />
          {showName && <span>{displayName.split(' ')[0]}</span>}
        </a>
    );
}
 
export default ProfileThumb;

