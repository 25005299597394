import React, { useContext } from "react"
import { ActivityContext } from "./social/ActivityContext"
import LikeButton from "./social/LikeButton"
import UserBar from "./social/UserBar"
import { DocumentDuplicate } from "@styled-icons/heroicons-outline/DocumentDuplicate"
import Share from "./Share"
import { FirebaseContext } from "./Firebase"
import { useRouter } from "next/router"
import LoadingButton, { Button } from "./LoadingButton"

import { Pencil as EditIcon } from "@styled-icons/heroicons-solid/Pencil"
import firebase from "firebase/app"
import BoardImage from "./BoardImage"
import dynamic from "next/dynamic"
import useRedirectToProfile from "./useRedirectToProfile"
import {Share as ShareIcon} from '@styled-icons/heroicons-solid/Share'

const StreamApp = dynamic(() => import("./social/StreamApp"))
const ActivityProvider = dynamic(() => import("./social/ActivityContext"))

function BoardHead({ board }) {
  const {
    id: boardId,
    image,
    imageBase64,
    canvas,
    user,
    products,
    title,
    description,
    created,
    isImageDirty,
  } = board
  const router = useRouter()
  const fb = useContext(FirebaseContext)
  const {
    logEvent,
    user: fbUser,
    loadBoardOnce,
    createBoard,
    saveBoard,
    deleteBoard,
    uploadBoardImage,
    isOwner,
    app,
    isAdmin,
  } = fb
  const isBoardOwner = isOwner(user)
  const [inpirations, setInpirations] = React.useState(null)
  
  const redirect = useRedirectToProfile()

  const onDuplicate = async () => {
    if (!fbUser || fbUser.isAnonymous) {
      redirect()
      return
    }
    const result = await createBoard(products, canvas)
    if (result instanceof Error) {
      console.error(result)
      return
    } else if (result) router.push(`/edit/${result}`)
  }

  const [selectedRoomType, setSelectedRoomType] = React.useState("Living Room");


  const styles = ['Contemporary', '', 'Modern', 'Traditional', 'Rustic', 'Minimalist', 'Industrial', 'Coastal', 'Bohemian', 'Scandinavian', 'Mid-Century Modern'];
  const roomTypes = ['Bedroom', 'Living Room', 'Kitchen', 'Bathroom', 'Dining Room', 'Home Office', 'Baby Room'];
  const textures = [''];
  // const textures = ['','Wood', 'Metal', 'Fabric', 'Tile', 'Stone', 'Concrete', 'Glass'];
  const colorCombinations = [
    [],
    // Neutrals
    ['White', 'Light Grey', 'Dark Grey'],
    ['White', 'Beige', 'Light Brown'],
    ['White', 'Black', 'Red'],
    ['White', 'Off-White', 'Navy Blue'],
    
    // Pastels
    ['Light Pink', 'Light Blue', 'Light Green'],
    ['Lavender', 'Light Pink', 'Light Grey'],
    ['Mint Green', 'Light Yellow', 'Peach'],
    ['Light Orange', 'Light Purple', 'Light Blue'],
    
    // Brights
    ['Yellow', 'Orange', 'Pink'],
    ['Red', 'Orange', 'Yellow'],
    ['Blue', 'Green', 'Purple'],
    ['Pink', 'Purple', 'Magenta']
  ];

  async function createAllInspirations({ boardId }) {
    for (const style of styles) {
        for (const texture of textures) {
          for (const colorCombination of colorCombinations) {
            console.log(
              "creating inspiration... ",
              style,
              texture,
              colorCombination,
            )
            await createInpsiration({
              boardId,
              style,
              roomType: selectedRoomType,
              texture,
              colorCombination,
            })
          }
        }
    }
  }

  const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
  const createInpsiration = async (params) => {
    await import("firebase/functions")
    const createInspiration = app
      .functions()
      .httpsCallable("createInspiration")
    try {
      const response = await createInspiration(params)

      let prediction = response.data

      while (
        prediction.status !== "succeeded" &&
        prediction.status !== "failed"
      ) {
        await sleep(1000)
        const response = await createInspiration({
          predictionId: prediction.id,
        })
        prediction = response.data
        if (prediction.status === "succeeded") {
          setInpirations(prediction.output)
        }
      }
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <div className="md:flex lg:rounded-xl md:border md:shadow-md md:mb-8">
      <div className="w-full h-full md:w-3/5 p-3 border shadow-md md:border-0 md:shadow-none">
        <BoardImage src={image} width="620" height="500" alt={title} boardId={boardId} isImageDirty={isImageDirty} />
      </div>
      <div className="md:w-2/5 px-8 md:pb-10">
      <div className="h-36 sm:h-1/3 mb-10">
      <StreamApp showLoading={false}>
        <ActivityProvider
          boardId={boardId}
          time={created}
        >
          <div className="py-3 md:py-5 flex">
            <ActivityContext.Consumer>
              {activityProps =>
                activityProps &&
                activityProps.activity && (
                  <LikeButton
                    {...activityProps}
                    Wrapper={({ children }) => (
                      <div className="flex-shrink-0 cursor-pointer inline-flex items-center justify-center text-lg text-gray-700 my-3 ml-1 px-4 py-2 rounded-full focus:outline-none disabled:opacity-50 hover:bg-gray-100">
                        {children}
                      </div>
                    )}
                  />
                )
              }
            </ActivityContext.Consumer>
            {isBoardOwner && (
              <LoadingButton
                onClick={() => router.push(`/edit/${boardId}`)}
                highlight
              >
                <EditIcon className="h-6" />
              </LoadingButton>
            )}
            {/* <LoadingButton onClick={onDuplicate}>
              <DocumentDuplicate className="h-6" />
            </LoadingButton> */}
            <div className="w-full flex items-center justify-end my-1 mx-1">
              <Share
                boardId={boardId}
                image={image}
                isImageDirty={isImageDirty}
                title={title}
                description={description}
              >
                {open => (<button className={`h-12 w-12 flex items-center justify-center hover:bg-gray-100 rounded-full ${open ? 'bg-gray-100' : ''}`} >
<ShareIcon className="text-gray-600 h-6"/>
</button>)}
                </Share>
            </div>
          </div>
          <div className="md:px-5">
            <ActivityContext.Consumer>
              {activityProps =>
                activityProps &&
                activityProps.activity && (
                  <UserBar uid={activityProps.activity.actor.id} showName />
                )
              }
            </ActivityContext.Consumer>
          </div>
        </ActivityProvider></StreamApp>
        </div>
          <h1 className="text-2xl text-gray-800 md:px-5 py-2 md:py-4 text-center md:text-left">
            {title}
          </h1>
          <h2 className="text-lg text-gray-600 px-5 py-3 hidden md:block">
            {description}
          </h2>
      </div>
    </div>
  )
}

export default BoardHead
