// @flow
import * as React from 'react';


// import { smartRender } from '../utils';
import { Avatar, withTranslationContext } from 'react-activity-feed';
import ProfileThumb from '../ProfileThumb'
import FollowButton from './FollowButton'

// export type Props = {|
//   username: ?string,
//   avatar?: string,
//   subtitle?: string,
//   time?: string, // text that should be displayed as the time
//   timestamp?: string | number, // a timestamp that should be humanized
//   icon?: string,
//   onClickUser?: () => mixed,
//   follow?: boolean,
//   Right?: Renderable,
//   AfterUsername?: React.Node,
// |} & Streami18Ctx;

/**
 * Component is described here.
 *
 * @example ./examples/UserBar.md
 */
class UserBar extends React.Component {
  render() {
    return (
      <>
        {this.props.uid && 
                <div className="flex items-center mb-4">
                    <ProfileThumb uid={this.props.uid} showName={this.props.showName}/>
                    <div className="ml-auto">
                        <FollowButton userToFollow={this.props.uid}/>
                    </div>
                </div>
        }
        {!this.props.uid && this.props.avatar ? (
          <Avatar
            onClick={this.props.onClickUser}
            size={50}
            circle
            image={this.props.avatar}
          />
        ) : null}
        {/* <div className="raf-user-bar__details">
          <p
            className="raf-user-bar__username"
            onClick={this.props.onClickUser}
          >
            {this.props.username}
          </p>
          {this.props.AfterUsername}
          {this.props.icon !== undefined ? (
            <img src={this.props.icon} alt="icon" />
          ) : null}
        </div> */}
      </>
    );
  }
}

export default withTranslationContext(UserBar);
